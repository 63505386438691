import React from "react"

const Services = () => {
  return (
    <div className="services-wrapper animated fadeInUp">
      <div className="services-left">
        <h2 className="h2">Vorsorgen</h2>
        <h2 className="h2">Veranlagen</h2>
      </div>
      <div className="services-right">
        <h2 className="h2">Versichern</h2>
        <h2 className="h2">Finanzieren</h2>
      </div>
    </div>
  )
}

export default Services
