import PropTypes from "prop-types"
import React from "react"

import Logo from "./logo"

const Header = ({ siteTitle }) => (
  <header className="header">
    <Logo />
    <h3 className="header-slogan h3 animated fadeInDown delay-600">
      Beratung mit Weitblick
    </h3>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
