import React from "react"
import HeroIMG from "../images/hero.jpg"

const Hero = () => {
  return (
    <div className="hero-wrapper">
      <img
        src={HeroIMG}
        alt="Büro der Dolomiten Finanz OG"
        className="hero-img"
      />
    </div>
  )
}

export default Hero
