import React from "react"

import kreditvermittlung from "../pdf/kreditvermittlung.pdf"
import versicherungsmakler from "../pdf/versicherungsmakler.pdf"
import finanzdienstleistung from "../pdf/finanzdienstleistung.pdf"

const AGB = () => {
  return (
    <div className="agb-wrapper">
      <h3>
        <a href={kreditvermittlung} target="_blank" rel="noopener noreferrer">
          Kreditvermittlungs-AGB
        </a>
      </h3>
      <h3>
        <a href={versicherungsmakler} target="_blank" rel="noopener noreferrer">
          Versicherungsmakler-AGB
        </a>
      </h3>
      <h3>
        <a
          href={finanzdienstleistung}
          target="_blank"
          rel="noopener noreferrer"
        >
          Finanzdienstleistungs-AGB
        </a>
      </h3>
    </div>
  )
}

export default AGB
