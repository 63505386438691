import React from "react"
import LogoSVG from "../images/logo.svg"

const Logo = () => {
  return (
    <div className="logo-wrapper animated fadeInDown">
      <LogoSVG />
    </div>
  )
}

export default Logo
