/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Hero from "./hero"
import Footer from "./footer"
import Lightbox from "./lightbox"
//import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [lightbox, setLightbox] = useState({
    open: false,
    target: "",
  })

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Hero />
      {children}
      <Footer setLightbox={setLightbox} />
      <Lightbox
        target={lightbox.target}
        activeClass={lightbox.open ? "active" : ""}
        setLightbox={setLightbox}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
