import React from "react"
import datenschutz from "../pdf/datenschutz.pdf"

const Footer = ({ setLightbox }) => {
  return (
    <footer className="footer-wrapper animated fadeInUp">
      <div className="footer-left uppercase">
        kennen sie unsere vermietung:{" "}
        <a
          href="https://www.dolomitenwohnen.at/"
          className="footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          dolomitenwohnen.at
        </a>
      </div>
      <div className="footer-right">
        <ul className="footer-linklist">
          <li className="footer-linklist-link">
            Geschäftsführer: Martin Korber
          </li>

          <li
            className="footer-linklist-link"
            onClick={() =>
              setLightbox({
                open: true,
                target: "AGB",
              })
            }
          >
            AGBs{" "}
          </li>
          <li
            className="footer-linklist-link"
            onClick={() =>
              setLightbox({
                open: true,
                target: "IMPRESSUM",
              })
            }
          >
            Impressum
          </li>
          <li className="footer-linklist-link">
            <a href={datenschutz} target="_blank" rel="noopener noreferrer">
              Datenschutz
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
