import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/services"
import Spinner from "../components/spinner"

import "../scss/style.scss"
import "../scss/animate.min.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main>
      <div className="main-left">
        <div className="intro">
          <h1 className="h1 animated fadeInUp">Unser neues Büro.</h1>
          <Services />
        </div>
        <Spinner />
      </div>

      <div className="main-right">
        <h3 className="h3 animated fadeInUp delay-600">
          Der Kundennutzen steht im Mittelpunkt unserer Arbeit.
        </h3>
        <div className="contact">
          <div className="contact-left animated fadeInUp delay-600">
            Dolomitenfinanz GmbH </p>
            <p>Tiroler Straße 3 / 9991 Dölsach</p>
            <p>office@dolomitenfinanz.at</p>
          </div>
          <div className="contact-right animated fadeInUp delay-900">
            <p>
              Tel:<span className="spacing">+43 (0) 677 617 54454 </span>
            </p>
            <p>
              Mobil:
              <span className="spacing">+43 (0) 699 12 300 500</span>
            </p>
            </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default IndexPage
