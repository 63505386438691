import React from "react"
import SpinnerSVG from "../images/standortwechsel.svg"

const Spinner = () => {
  return (
    <div className="spinner-wrapper animated rotateIn">
      <SpinnerSVG className="spinner-svg" />
    </div>
  )
}

export default Spinner
