import React from "react"

import Impressum from "./impressum"
import AGB from "./agb"

import Close from "../images/close.svg"

const Lightbox = ({ target, activeClass, setLightbox }) => {
  return (
    <div
      className={"lightbox-wrapper " + activeClass}
      style={{ "overflow-y": target === "AGB" ? "hidden" : "scroll" }}
    >
      {(() => {
        switch (target) {
          case "IMPRESSUM":
            return <Impressum />
          case "AGB":
            return <AGB />
          default:
            return null
        }
      })()}
      <div
        className="lightbox-close"
        onClick={() => {
          setLightbox({
            open: false,
            target: "",
          })
        }}
      >
        <Close />
      </div>
    </div>
  )
}

export default Lightbox
